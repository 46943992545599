import { render, staticRenderFns } from "./OrganisationLibraryMechanic.vue?vue&type=template&id=b79e8684&scoped=true&"
import script from "./OrganisationLibraryMechanic.vue?vue&type=script&lang=js&"
export * from "./OrganisationLibraryMechanic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b79e8684",
  null
  
)

export default component.exports